import React, { createContext, useState, useEffect } from 'react';

export const CurrencyContext = createContext();

export const CurrencyProvider = ({ children }) => {
  // Initialize isInr from localStorage or default to false
  const [isInr, setIsInr] = useState(() => {
    const savedIsInr = localStorage.getItem('isInr');
    return savedIsInr !== null ? JSON.parse(savedIsInr) : false;
  });

  // Save isInr to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem('isInr', JSON.stringify(isInr));
  }, [isInr]);

  const toggleCurrency = () => {
    setIsInr((prevIsInr) => !prevIsInr);
  };

  return (
    <CurrencyContext.Provider value={{ isInr, toggleCurrency }}>
      {children}
    </CurrencyContext.Provider>
  );
};
