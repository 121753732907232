import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import useAuth from '../hooks/useAuth';

const Session = () => {
  const navigate = useNavigate();
  const { login } = useAuth();
  const [searchParams] = useSearchParams();
  const queryParams = Object.fromEntries([...searchParams]);
  const user = JSON.parse(queryParams.user);

  login({
    user,
    accessToken: queryParams.accessToken,
    refreshToken: queryParams.refreshToken,
  });

  useEffect(() => {
    navigate('/dashboard');
  }, [navigate]);

  return <div>redirecting..</div>;
};

export default Session;
