import { useState } from 'react';
import instance from '../server/axios';

const useLazyApi = (
  url,
  options,
  { onError, onSuccess } = {
    onError: () => { },
    onSuccess: () => { },
  },
) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const call = async ({
    url: newURL, payload
  }) => {
    setLoading(true);
    try {
      const data = await instance(newURL || url, {
        method: 'POST',
        ...options,
        data: payload,
      });
      setData(data.data);
      onSuccess?.(data.data);
      return data.data;
    } catch (error) {
      setError(error);
      onError?.(error);
    } finally {
      setLoading(false);
    }
  };

  return [
    call,
    {
      data,
      loading,
      error,
    },
  ];
};

export default useLazyApi;
