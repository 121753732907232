import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowLeft,
  faPaperclip,
  faBars,
} from '@fortawesome/free-solid-svg-icons';
import {
  Result,
  Button,
  Input,
  Modal,
  Select,
  Skeleton,
  Spin,
  Drawer,
  Menu,
  Card,
} from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import useApi from '../../hooks/useApi';
import useLazyApi from '../../hooks/useLazyApi';
import { formatIsoDate } from '../../utils/date';
import styles from './admin.module.scss';

const { Option } = Select;
const skeletonCards = new Array(3).fill(0);

const Admin = () => {
  const navigate = useNavigate();

  const {
    loading: approvedOrderLoading,
    data: approvedOrders,
    refresh: refreshApprovedOrder,
  } = useApi(`/order/admin/approved`);

  const {
    loading: rejectedOrderLoading,
    data: rejectedOrders,
    refresh: refreshRejectedOrder,
  } = useApi(`/order/admin/rejected`);

  const {
    loading: orderLoading,
    data: orders,
    refresh: refreshOrder,
  } = useApi(`/order/admin/pending`);

  // Fetch products data
  const { data: products, refresh } = useApi('/products');

  const [isApproveModalVisible, setIsApproveModalVisible] = useState(false);
  const [isRejectModalVisible, setIsRejectModalVisible] = useState(false);
  const [isPriceModalVisible, setIsPriceModalVisible] = useState(false);
  const [comments, setComments] = useState({});
  const [isCommentValid, setIsCommentValid] = useState(true);
  const [selectedOrderId, setSelectedOrderId] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);

  const [newPrice, setNewPrice] = useState('');
  const [priceError, setPriceError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [menuVisible, setMenuVisible] = useState(false);
  const [currentView, setCurrentView] = useState('dashboard');

  const handleProductChange = (productId) => {
    const selectedProduct = products.find(
      (product) => product.id === productId,
    );
    // console.log(selectedProduct);

    setSelectedProduct(selectedProduct);
  };

  // Set up lazy API for updating product price
  const [updateBuyPrice] = useLazyApi(
    '/priceHistory/buy/update',
    {
      method: 'POST',
      headers: {},
    },
    {
      onSuccess: () => {
        setIsPriceModalVisible(false);
        setIsLoading(false);
      },
      onError: (error) => {
        console.error('Failed to update buy price:', error);
        setIsLoading(false);
      },
    },
  );

  const [updateSellPrice] = useLazyApi(
    '/priceHistory/sell/update',
    {
      method: 'POST',
      headers: {},
    },
    {
      onSuccess: () => {
        setIsPriceModalVisible(false);
        setIsLoading(false);
      },
      onError: (error) => {
        console.error('Failed to update sell price:', error);
        setIsLoading(false);
      },
    },
  );

  // Set up useLazyApi for the POST request to update order status
  const [updateOrderStatus] = useLazyApi(
    '/order/update',
    {},
    {
      onSuccess: () => {
        refreshOrder(); // Refresh pending orders
        refreshApprovedOrder(); // Refresh approved orders
        refreshRejectedOrder(); // Refresh rejected orders
        setIsApproveModalVisible(false);
        setIsRejectModalVisible(false);
      },
      onError: (error) => {
        console.error('Failed to update order:', error);
      },
    },
  );

  const handleBackClick = () => {
    navigate('/dashboard');
  };

  const showApproveModal = (orderId) => {
    setSelectedOrderId(orderId);
    setIsApproveModalVisible(true);
  };

  const handleApproveOk = () => {
    updateOrderStatus({
      payload: {
        id: selectedOrderId,
        status: 'APPROVED',
        comment: comments[selectedOrderId] || '',
      },
    });
  };

  const handleApproveCancel = () => {
    setIsApproveModalVisible(false);
  };

  const showRejectModal = (orderId) => {
    setSelectedOrderId(orderId);
    setIsRejectModalVisible(true);
  };

  const handleRejectOk = () => {
    if ((comments[selectedOrderId] || '').trim() === '') {
      setIsCommentValid(false);
      return;
    }

    updateOrderStatus({
      payload: {
        id: selectedOrderId,
        status: 'REJECTED',
        comment: comments[selectedOrderId],
      },
    });
  };
  const handleRejectCancel = () => {
    setIsRejectModalVisible(false);
  };

  const handleCommentChange = (orderId, e) => {
    setComments((prevComments) => ({
      ...prevComments,
      [orderId]: e.target.value,
    }));

    if (e.target.value.trim() !== '') {
      setIsCommentValid(true);
    }
  };

  const handleUpdatePriceClick = () => {
    setIsPriceModalVisible(true);
  };

  const handleUpdatePriceOk = async () => {
    if (!newPrice) {
      setPriceError('New price is required');
    } else if (parseFloat(newPrice) <= 0) {
      setPriceError('Price must be greater than zero');
    } else {
      setIsLoading(true);
      await Promise.all([
        updateBuyPrice({
          payload: { product_id: selectedProduct?.id, price: newPrice },
        }),
        updateSellPrice({
          payload: {
            product_id: selectedProduct?.id,
            price: newPrice,
          },
        }),
      ]);

      setIsLoading(false);
      refresh();
      setNewPrice('');
      setSelectedProduct(null);
      setPriceError('');
    }
  };

  const handleUpdatePriceCancel = () => {
    setIsPriceModalVisible(false);
    setSelectedProduct(null);
    setNewPrice('');
    setPriceError('');
  };

  const handleMenuClick = () => {
    setMenuVisible(!menuVisible);
  };

  const handleMenuItemClick = (view) => {
    setCurrentView(view);
    setMenuVisible(false); // Close the menu after clicking
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.topNav}>
        <div className={styles.navWrapper}>
          <div className={styles.backIcon} onClick={handleBackClick}>
            <FontAwesomeIcon
              size="2xl"
              icon={faArrowLeft}
              style={{ color: '#ffffff' }}
            />
          </div>
          <div className={styles.heading}>
            <h1>Admin Dashboard</h1>
          </div>
          <div className={styles.hamburgerIcon}>
            <FontAwesomeIcon
              icon={faBars}
              size="xl"
              className={styles.hamburgerIcon}
              onClick={handleMenuClick}
            />
          </div>
        </div>
      </div>

      <Drawer
        title="Menu"
        placement="right"
        closable={true}
        onClose={() => setMenuVisible(false)}
        open={menuVisible}
        width={250}
        bodyStyle={{ backgroundColor: '#080402', color: '#fff' }}
        headerStyle={{ backgroundColor: '#080402', color: '#fff' }}
        closeIcon={<CloseOutlined style={{ color: '#fff' }} />}
      >
        <Menu
          mode="inline"
          defaultSelectedKeys={['dashboard']}
          style={{
            backgroundColor: '#080402',
            color: '#fff',
          }}
          onClick={(e) => handleMenuItemClick(e.key)}
          selectedKeys={[currentView]} // Set selected menu item
        >
          <Menu.Item
            style={{
              backgroundColor:
                currentView === 'dashboard' ? '#A9A9A9' : '#080402',
              color: '#fff',
              fontWeight: 'bold',
              border: currentView === 'dashboard' ? 'transparent' : 'none',
              borderRadius: '6px',
            }}
            key="dashboard"
          >
            Admin Dashboard
          </Menu.Item>
          <Menu.Item
            style={{
              backgroundColor:
                currentView === 'approved' ? '#A9A9A9' : '#080402',
              color: '#fff',
              fontWeight: 'bold',
              border: currentView === 'dashboard' ? 'transparent' : 'none',
              borderRadius: '6px',
            }}
            key="approved"
          >
            Approved Orders
          </Menu.Item>
          <Menu.Item
            style={{
              backgroundColor:
                currentView === 'rejected' ? '#A9A9A9' : '#080402',
              color: '#fff',
              fontWeight: 'bold',
              border: currentView === 'dashboard' ? 'transparent' : 'none',
              borderRadius: '6px',
            }}
            key="rejected"
          >
            Rejected Orders
          </Menu.Item>
        </Menu>
      </Drawer>

      {currentView === 'dashboard' && (
        <>
          <div className={styles.updatePrice}>
            <Button
              className={styles.action}
              block
              type="primary"
              onClick={handleUpdatePriceClick}
            >
              Update Price
            </Button>
          </div>

          {!orderLoading && !orders?.length && (
            <Result
              title={
                <div className={styles.noTransaction}>
                  No Pending Request yet
                </div>
              }
            />
          )}

          {orderLoading && (
            <div className={styles.loadingContainer}>
              {skeletonCards.map((_, index) => (
                <Skeleton key={index} />
              ))}
            </div>
          )}

          <div className={styles.allTransactionContainer}>
            {orders?.map((order) => (
              <div className={styles.transactionContainer} key={order.id}>
                <Card className={styles.customCard}>
                  <div className={styles.main}>
                    <div className={styles.top}>
                      <div className={styles.orderType}>
                        <div
                          className={
                            order.type === 'buy' ? styles.buy : styles.sell
                          }
                        >
                          <h1>{order.type === 'buy' ? 'B' : 'S'}</h1>
                        </div>
                        <h1>
                          {order?.Product?.name}
                          {order.proof && (
                            <a
                              href={order.proof}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <FontAwesomeIcon
                                icon={faPaperclip}
                                color="#1877f2"
                              />
                            </a>
                          )}
                        </h1>
                      </div>
                      <div
                        className={styles[`${order?.status?.toLowerCase()}`]}
                      >
                        <p>{order?.status?.toLowerCase()}</p>
                      </div>
                    </div>
                    <div className={styles.bottom}>
                      {order.transaction_id && (
                        <span className={styles.txn}>
                          Txn: {order.transaction_id}
                        </span>
                      )}
                      <h3>{order.User.email}</h3>
                      <p>
                        {order.type === 'buy' ? 'buy' : 'sell'} {order.quantity}{' '}
                        Qty @ {order.total_value / order.quantity} on{' '}
                        {formatIsoDate(order.createdAt)}
                      </p>
                      <div className={styles.commentBox}>
                        <Input
                          style={{ borderRadius: '15px' }}
                          type="text"
                          placeholder="Comment"
                          value={comments[order.id] || ''}
                          onChange={(e) => handleCommentChange(order.id, e)}
                        />
                      </div>
                      <div className={styles.actionArea}>
                        <Button
                          className={styles.actionSuccess}
                          block
                          type="primary"
                          onClick={() => showApproveModal(order.id)}
                        >
                          Approve
                        </Button>
                        <Button
                          type="primary"
                          danger
                          block
                          className={styles.action}
                          onClick={() => showRejectModal(order.id)}
                        >
                          Reject
                        </Button>
                      </div>
                    </div>
                  </div>
                </Card>
              </div>
            ))}
          </div>
        </>
      )}

      <Modal
        title="Approve Transaction"
        open={isApproveModalVisible}
        onOk={handleApproveOk}
        onCancel={handleApproveCancel}
      >
        <p>Are you sure you want to approve this transaction?</p>
      </Modal>

      <Modal
        title="Reject Transaction"
        open={isRejectModalVisible}
        onOk={handleRejectOk}
        onCancel={handleRejectCancel}
      >
        <p>Are you sure you want to reject this transaction?</p>
        <Input
          type="text"
          placeholder="Comment (required)"
          value={comments[selectedOrderId] || ''}
          onChange={(e) => handleCommentChange(selectedOrderId, e)}
        />
        {!isCommentValid && (
          <p className={styles.commentValid}>Comment is required to reject.</p>
        )}
      </Modal>

      {/* Approved Orders */}

      {currentView === 'approved' && (
        <div>
          <div className={styles.approvedTopNav}>
            <div className={styles.approvedHeading}>
              <h1>Approved Orders</h1>
            </div>
          </div>

          {!approvedOrderLoading && !approvedOrders?.length && (
            <Result
              title={
                <div className={styles.noTransaction}>
                  No Approved Orders yet
                </div>
              }
            />
          )}

          {approvedOrderLoading && (
            <div className={styles.loadingContainer}>
              {skeletonCards.map((_, index) => (
                <Skeleton key={index} />
              ))}
            </div>
          )}

          <div className={styles.allTransactionContainer}>
            {approvedOrders?.map((order) => (
              <div className={styles.transactionContainer} key={order.id}>
                <Card className={styles.customCard}>
                  <div className={styles.main}>
                    <div className={styles.top}>
                      <div className={styles.orderType}>
                        <div
                          className={
                            order.type === 'buy' ? styles.buy : styles.sell
                          }
                        >
                          <h1>{order.type === 'buy' ? 'B' : 'S'}</h1>
                        </div>
                        <h1>
                          {order?.Product?.name}
                          {order.proof && (
                            <a
                              href={order.proof}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <FontAwesomeIcon
                                icon={faPaperclip}
                                color="#1877f2"
                              />
                            </a>
                          )}
                        </h1>
                      </div>
                      <div
                        className={styles[`${order?.status?.toLowerCase()}`]}
                      >
                        <p>{order?.status?.toLowerCase()}</p>
                      </div>
                    </div>
                    <div className={styles.bottom}>
                      {order.transaction_id && (
                        <span className={styles.txn}>
                          Txn: {order.transaction_id}
                        </span>
                      )}
                      <h3>{order.User.email}</h3>
                      <p>
                        {order.type === 'buy' ? 'buy' : 'sell'} {order.quantity}{' '}
                        Qty @ {order.total_value / order.quantity} on{' '}
                        {formatIsoDate(order.createdAt)}
                      </p>
                    </div>
                  </div>
                </Card>
              </div>
            ))}
          </div>
        </div>
      )}

      {/* Rejected Orders */}

      {currentView === 'rejected' && (
        <div>
          <div className={styles.approvedTopNav}>
            <div className={styles.approvedHeading}>
              <h1>Rejected Orders</h1>
            </div>
          </div>

          {!rejectedOrderLoading && !rejectedOrders?.length && (
            <Result
              title={
                <div className={styles.noTransaction}>
                  No Rejected Orders yet
                </div>
              }
            />
          )}

          {rejectedOrderLoading && (
            <div className={styles.loadingContainer}>
              {skeletonCards.map((_, index) => (
                <Skeleton key={index} />
              ))}
            </div>
          )}

          <div className={styles.allTransactionContainer}>
            {rejectedOrders?.map((order) => (
              <div className={styles.transactionContainer} key={order.id}>
                <Card className={styles.customCard}>
                  <div className={styles.main}>
                    <div className={styles.top}>
                      <div className={styles.orderType}>
                        <div
                          className={
                            order.type === 'buy' ? styles.buy : styles.sell
                          }
                        >
                          <h1>{order.type === 'buy' ? 'B' : 'S'}</h1>
                        </div>
                        <h1>
                          {order?.Product?.name}
                          {order.proof && (
                            <a
                              href={order.proof}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <FontAwesomeIcon
                                icon={faPaperclip}
                                color="#1877f2"
                              />
                            </a>
                          )}
                        </h1>
                      </div>
                      <div
                        className={styles[`${order?.status?.toLowerCase()}`]}
                      >
                        <p>{order?.status?.toLowerCase()}</p>
                      </div>
                    </div>
                    <div className={styles.bottom}>
                      {order.transaction_id && (
                        <span className={styles.txn}>
                          Txn: {order.transaction_id}
                        </span>
                      )}
                      <h3>{order.User.email}</h3>
                      <p>
                        {order.type === 'buy' ? 'buy' : 'sell'} {order.quantity}{' '}
                        Qty @ {order.total_value / order.quantity} on{' '}
                        {formatIsoDate(order.createdAt)}
                      </p>
                    </div>
                  </div>
                </Card>
              </div>
            ))}
          </div>
        </div>
      )}

      <Modal
        title="Update Product Price"
        open={isPriceModalVisible}
        onOk={handleUpdatePriceOk}
        onCancel={handleUpdatePriceCancel}
        okButtonProps={{
          disabled: isLoading,
          icon: isLoading ? <Spin /> : null,
        }}
      >
        <div>
          <div>
            <label>Product:</label>
            <Select
              value={selectedProduct?.id}
              onChange={handleProductChange}
              placeholder="Select a product"
              style={{ width: '100%' }}
            >
              {products?.map((product) => (
                <Option key={product.id} value={product.id}>
                  {product.name}
                </Option>
              ))}
            </Select>
          </div>
          <div className={styles.currentPrice}>
            <label>Current Price: {selectedProduct?.sell_price}</label>
          </div>
          <div style={{ marginTop: '0.5rem' }}>
            <label style={{ marginRight: '0.4rem' }}>Update Price:</label>
            <Input
              value={newPrice}
              onChange={(e) => {
                const value = e.target.value;
                setNewPrice(value);
              }}
              placeholder="Enter new price"
              type="number"
              min="0"
            />
          </div>
          {priceError && (
            <p
              style={{
                color: '#ff474c',
                fontSize: '13px',
                marginLeft: '2px',
              }}
            >
              {priceError}
            </p>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default Admin;
