import { createContext, useState } from 'react';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const localUser = localStorage.getItem('user')
    ? JSON.parse(localStorage.getItem('user'))
    : null;
  const [user, setUser] = useState(localUser);

  const login = ({ user, accessToken, refreshToken }) => {
    setUser(user);

    localStorage.setItem('user', JSON.stringify(user));
    localStorage.setItem('accessToken', accessToken);
    localStorage.setItem('refreshToken', refreshToken);
  };

  const logout = () => {
    localStorage.clear();
    window.location = '/';
  };

  return (
    <AuthContext.Provider value={{ user, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
