export const TRANSACTION_STATUS = Object.freeze({
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
  PENDING: 'APPROVED',
});

export const ROLE = Object.freeze({
  USER: 'user',
  ADMIN: 'admin',
});
