export const formatCurrency = (amount, isInr) => {
  // Ensure the amount is a number
  const numericAmount = Number(amount);

  // If the conversion fails, default to 0
  if (isNaN(numericAmount)) {
    console.error(`Invalid amount passed to formatCurrency: ${amount}`);
    return isInr ? `INR 0.00` : `USDT 0.00`;
  }

  // Convert amount to INR if needed
  const convertedAmount = isInr ? numericAmount * 84 : numericAmount;

  // Format the number to two decimal places
  const formattedAmount = convertedAmount.toFixed(2);

  // Return the formatted currency string
  return isInr ? `INR ${formattedAmount}` : `USDT ${formattedAmount}`;
};
