// src/ProtectedRoute.js
import React from 'react';
import { Navigate } from 'react-router-dom';
import useAuth from '../hooks/useAuth';

const PublicRoute = ({ children, disableOnAuth }) => {
  const auth = useAuth();

  if (disableOnAuth && auth.user) {
    return <Navigate to="/dashboard" />;
  }

  return children;
};

export default PublicRoute;
