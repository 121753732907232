import React, { useEffect, useRef, useContext } from 'react';
import Chart from 'chart.js/auto';
import 'chartjs-adapter-date-fns';
import { CurrencyContext } from '../../src/context/CurrencyContext';
import { formatCurrency } from '../../src/utils/CurrencyFormatter';

const LineChart = ({ data, title }) => {
  const chartRef = useRef(null);

  // Use the context
  const { isInr } = useContext(CurrencyContext);

  useEffect(() => {
    const ctx = chartRef.current.getContext('2d');

    const chartInstance = new Chart(ctx, {
      type: 'line',
      data: {
        labels: data.map((item) => new Date(item.date)),
        datasets: [
          {
            label: isInr ? 'INR' : 'USDT', // Remove the label from the dataset
            data: data.map((item) => (isInr ? item.value * 84 : item.value)), // Multiply by 84 for INR
            fill: false,
            borderColor: 'rgba(75,192,192,1)',
            tension: 0.1,
          },
        ],
      },
      options: {
        scales: {
          x: {
            type: 'time',
            time: {
              unit: 'day',
              tooltipFormat: 'MMM dd, yyyy',
            },
            title: {
              display: false,
              text: 'Date',
            },
            grid: {
              display: false, // Remove grid lines on x-axis
            },
          },
          y: {
            title: {
              display: false,
              text: 'Value',
            },
            grid: {
              display: true, // Display grid lines on y-axis
            },
            ticks: {
              callback: function (value) {
                // Format the value as currency
                let formattedValue = formatCurrency(value, isInr);

                // Remove the currency symbol and extract the numeric value
                formattedValue = formattedValue.replace(/[^\d.-]/g, '');

                // Convert values greater than or equal to 1000 into 'k' format
                if (value >= 1000) {
                  formattedValue = (value / 1000).toFixed(2) + 'k';
                }

                return formattedValue;
              },
            },
          },
        },
        plugins: {
          legend: {
            display: false, // Disable the legend
          },
          title: {
            display: true,
            text: title,
          },
        },
        tooltip: {
          enabled: true, // Enable tooltips
          callbacks: {
            label: function (context) {
              let label = '';
              label += formatCurrency(context.parsed.y, isInr); // Format tooltip value
              return label;
            },
          },
        },
      },
    });

    return () => {
      chartInstance.destroy();
    };
  }, [data, isInr, title]);

  return <canvas ref={chartRef}></canvas>;
};

export default LineChart;
