import React from 'react';
import styles from './Login.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfinity } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'antd';

const Login = () => {
  return (
    <main className={styles.main}>
      <div className={styles.lightEffect}></div>

      <div className={styles.showcaseArea}>
        <img
          src="/showcaseCrop.png"
          alt="Vercel Logo"
          className={styles.showcaseImage}
          width={300}
          height={300}
        />

        <div>
          <div className={styles.brandTitle}>
            <FontAwesomeIcon
              size="2xl"
              icon={faInfinity}
              style={{ color: '#63E6BE' }}
            />
            <h2>Fx Monk AI</h2>
          </div>
          <h4 className={styles.brandTagline}>Trade Smart with AI</h4>
        </div>

        <Button
          size="large"
          className={styles.googleSignin}
          icon={
            <img
              src="/googleIcon.png"
              alt="google icon"
              width={25}
              height={25}
            />
          }
          href={`${process.env.REACT_APP_API_URL}/auth/google`}
        >
          Sign in with google
        </Button>
      </div>
    </main>
  );
};

export default Login;
