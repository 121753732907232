import React, { useEffect, useMemo, useState } from 'react';
import sassStyles from './Navbar.module.scss';
import { Menu } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faHouse,
  faMoneyBill1,
  faUserTie,
} from '@fortawesome/free-solid-svg-icons';
import { useLocation, useNavigate } from 'react-router-dom';
import asset from './asset.png';
import useAuth from '../../hooks/useAuth';
import { ROLE } from '../../utils/constant';

const styles = {
  navbar: {
    position: 'fixed',
    bottom: 0,
    width: '100%',
    backgroundColor: '#001529',
  },
  menu: {
    display: 'flex',
    justifyContent: 'center',
  },
  menuItem: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
  },
};

const Navbar = () => {
  const auth = useAuth();
  const [current, setCurrent] = useState('home');
  const navigate = useNavigate();
  const location = useLocation();
  const onClick = (e, item) => {
    setCurrent(e.key);
    navigate(e.key);
  };

  useEffect(() => {
    const key = location.pathname.split('/')[1];
    if (itemKeys.includes(key)) {
      setCurrent(key);
    }
  }, [location]);

  const items = useMemo(() => {
    const menu = [
      {
        key: 'dashboard',
        label: <FontAwesomeIcon size="xl" icon={faHouse} />,
      },
      {
        key: 'transaction',
        label: <FontAwesomeIcon size="xl" icon={faMoneyBill1} />,
      },
      {
        key: 'portfolio',
        label: <img src={asset} className={sassStyles.house} />,
      },
    ];
    if (auth.user.role === ROLE.ADMIN) {
      menu.push({
        key: 'admin',
        label: <FontAwesomeIcon size="xl" icon={faUserTie} />,
      });
    }
    return menu;
  }, [auth]);

  const itemKeys = items.map((item) => item.key);

  return (
    <div style={styles.navbar}>
      <Menu
        onClick={onClick}
        selectedKeys={[current]}
        mode="horizontal"
        theme="dark"
        expandIcon={false}
        style={styles.menu}
      >
        {items.map((menu) => (
          <Menu.Item
            key={menu.key}
            style={styles.menuItem}
            {...(menu.key === 'portfolio' && {
              className: sassStyles.houseItemStyles,
            })}
          >
            {menu.label}
          </Menu.Item>
        ))}
      </Menu>
    </div>
  );
};

export default Navbar;
