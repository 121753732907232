import './App.css';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Login from './pages/Login';
import Dashboard from './pages/Dashboard/';
import { ConfigProvider } from 'antd';
import Root from './Layout/root';
import Session from './pages/Session';
import BuySellForm from './components/BuySellForm';
import { AuthProvider } from './context/Auth';
import ProtectedRoute from './route/ProtectedRoute';
import PublicRoute from './route/PublicRoute';
import Transaction from './pages/Transaction';
import Request from './pages/Request';
import Portfolio from './pages/Portfolio';
import { NotificationProvider } from './hooks/useNotification';
import Admin from './pages/Admin';
import { CurrencyProvider } from './context/CurrencyContext';

function App() {
  const router = createBrowserRouter([
    {
      path: '/',
      element: <Root />,
      children: [
        {
          path: '',
          element: (
            <PublicRoute disableOnAuth={true}>
              <Login />
            </PublicRoute>
          ),
        },
        {
          path: 'dashboard',
          element: (
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          ),
        },
        {
          path: 'request',
          element: (
            <ProtectedRoute>
              <Request />
            </ProtectedRoute>
          ),
        },
        {
          path: 'portfolio',
          element: (
            <ProtectedRoute>
              <Portfolio />
            </ProtectedRoute>
          ),
        },
        {
          path: 'transaction',
          element: (
            <ProtectedRoute>
              <Transaction />
            </ProtectedRoute>
          ),
        },
        {
          path: 'admin',
          element: (
            <ProtectedRoute onlyAdmin>
              <Admin />
            </ProtectedRoute>
          ),
        },
      ],
    },
    {
      path: '/session',
      element: <Session />,
    },
  ]);

  return (
    // <NotificationProvider>
    <ConfigProvider
      theme={{
        components: {
          Menu: {
            darkItemSelectedBg: '#161023',
            darkItemBg: '#000002',
          },
          Button: {
            colorPrimary: '#212121',
          },
        },
      }}
    >
      <div className="App">
        <AuthProvider>
          <CurrencyProvider>
            <RouterProvider router={router} />
          </CurrencyProvider>
        </AuthProvider>
      </div>
    </ConfigProvider>
    // </NotificationProvider>
  );
}

export default App;
