import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import advancedFormat from 'dayjs/plugin/advancedFormat';

// Use the plugins
dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);

export const formatIsoDate = (isoString) => {
  const date = dayjs(isoString);

  // Format the date as needed
  return date.format('MMMM DD YYYY');
};
