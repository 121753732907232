import { useContext } from 'react';
import { AuthContext } from '../context/Auth';

const useAuth = () => {
  const auth = useContext(AuthContext);

  if (!auth) {
    throw Error('User context in provider');
  }

  return auth;
};

export default useAuth;
