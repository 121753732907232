import React, { useContext, useState } from 'react';
import styles from './PortfolioSummary.module.scss';
import useAuth from '../../hooks/useAuth';
import useApi from '../../hooks/useApi';
import { Avatar, Modal, Button } from 'antd';
import { LogoutOutlined } from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRightArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { CurrencyContext } from '../../context/CurrencyContext';
import { formatCurrency } from '../../utils/CurrencyFormatter';

const PortfolioSummary = () => {
  const auth = useAuth();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const { loading: portfolioLoading, data: portfolio } = useApi(
    `/portfolio/${auth.user.id}`,
  );

  const { isInr, toggleCurrency } = useContext(CurrencyContext);

  const total = portfolio?.reduce((acc, product) => {
    acc += parseFloat(product.Product.buy_price * product.quantity);
    return acc;
  }, 0);

  const nameParts = auth.user.name.split(' ');
  const firstNameInitial = nameParts[0] ? nameParts[0][0] : '';

  const handleLogout = () => {
    // Call the logout function from your auth hook
    auth.logout();
    setIsModalVisible(false);
  };

  return (
    <div className={styles.portfolioSummary}>
      <div className={styles.portfolioHead}>
        <small className={styles.head}>Your portfolio</small>
        <div className={styles.amountContainer}>
          <h3>
            {!portfolioLoading && (
              <div
                className={styles.currencyToggle}
                onClick={toggleCurrency} // Toggle currency on click
                style={{
                  display: 'inline-flex',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
              >
                <FontAwesomeIcon
                  icon={faArrowRightArrowLeft}
                  className={styles.swapIcon}
                  style={{ marginRight: '8px' }}
                />
                {formatCurrency(total, isInr)}
              </div>
            )}
            {portfolioLoading && <div className={styles.loader} />}
          </h3>
        </div>
      </div>
      <div className={styles.userCard}>
        <div className={styles.top}>
          <div
            className={styles.logout}
            onClick={() => setIsModalVisible(true)}
          >
            <LogoutOutlined onClick={() => setIsModalVisible(true)} />
          </div>
        </div>

        <div className={styles.bottom}>
          <div className={styles.userName}>{auth.user.name}</div>
          <div>
            <Avatar>{firstNameInitial}</Avatar>
          </div>
        </div>
      </div>
      <Modal
        title="Confirm Logout"
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={[
          <Button key="cancel" onClick={() => setIsModalVisible(false)}>
            Cancel
          </Button>,
          <Button key="logout" type="primary" onClick={handleLogout}>
            Logout
          </Button>,
        ]}
      >
        <p className={styles.email}>{auth.user.email}</p>
        <p>Are you sure you want to logout?</p>
      </Modal>
    </div>
  );
};

export default PortfolioSummary;
