// src/ProtectedRoute.js
import React from 'react';
import { Navigate } from 'react-router-dom';
import useAuth from '../hooks/useAuth';
import { ROLE } from '../utils/constant';

const ProtectedRoute = ({ children, onlyAdmin }) => {
  const auth = useAuth();

  if (!auth.user) {
    return <Navigate to="/" />;
  }

  if (onlyAdmin && auth.user.role != ROLE.ADMIN) {
    return <Navigate to="/" />;
  }

  return children;
};

export default ProtectedRoute;
