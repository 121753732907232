import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CurrencyContext } from '../../context/CurrencyContext';
import styles from './Request.module.scss';
import { useSearchParams } from 'react-router-dom';
import useApi from '../../hooks/useApi';
import { Button, Result } from 'antd';
import useAuth from '../../hooks/useAuth';
import axios from 'axios';
import { UploadClient } from '@uploadcare/upload-client';
import { formatCurrency } from '../../../src/utils/CurrencyFormatter';

const client = new UploadClient({ publicKey: '3bd26cf973716b395675' });

const Request = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const productId = searchParams.get('productId');
  const transactionType = searchParams.get('transactionType');
  const holdings = parseInt(searchParams.get('holdings'), 10);
  const [inputs, setInputs] = useState({
    qty: 1,
  });
  const [errors, setErrors] = useState({});
  const [file, setFile] = useState(null);
  const [screenshotPreview, setScreenshotPreview] = useState(null);
  const [loading, setLoading] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);

  // Use the context
  const { isInr } = useContext(CurrencyContext);

  const isSell = transactionType === 'sell';

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0]; // Get the selected file
    if (selectedFile && selectedFile.type.startsWith('image/')) {
      setFile(selectedFile); // Update the state with the file

      // Create a preview URL for the selected file
      const reader = new FileReader();
      reader.onloadend = () => {
        setScreenshotPreview(reader.result); // Set the preview URL
      };
      reader.readAsDataURL(selectedFile);
    } else {
      alert('Please upload a valid image file');
    }
  };

  const toggleLoading = () => {
    setLoading((prevState) => !prevState);
  };

  const handleInputs = (e, name) => {
    const value = e.target.value;
    setInputs((prevInput) => {
      return {
        ...prevInput,
        [name]: isNaN(value) ? value : parseInt(value, 10),
      };
    });
  };

  const { productLoading, data: product } = useApi(`/products/${productId}`);

  function copyText() {
    const textArea = document.getElementById('textToCopy');

    // Select the text area content
    textArea.select();

    // Use the Clipboard API to copy the selected text
    navigator.clipboard
      .writeText(textArea.value)
      .then(() => {
        alert('Text copied to clipboard!');
      })
      .catch((err) => {
        console.error('Failed to copy text: ', err);
      });
  }

  const inputValidate = () => {
    const errors = {};
    if (inputs.qty <= 0) {
      errors['qty'] = 'Quantity should be greater or equal to 1';
    }

    if (isSell && inputs.qty > holdings) {
      errors['qty'] = 'Selling more quantity than you are holding';
    }

    if (isSell) {
      if (!inputs.paymentAdd) {
        errors['paymentAdd'] = 'Payment Address is required';
      }
    } else {
      if (!inputs.txnHash) {
        errors['txnHash'] = 'Transaction hash is required';
      }

      if (!file) {
        errors['file'] = 'Proof is required';
      }
    }

    return errors;
  };

  const handleSubmit = async () => {
    const errors = inputValidate();
    if (Object.keys(errors).length) {
      setErrors(errors);
      return; // Prevent form submission if there are errors
    } else {
      try {
        toggleLoading();
        let fields = {};

        if (!isSell) {
          const uploadedFile = await client.uploadFile(file);

          fields = {
            userId: auth.user.id,
            email: auth.user.email,
            productId,
            qty: inputs.qty,
            txnHash: inputs.txnHash,
            total: inputs.qty * product[`${transactionType}_price`],
            proof: uploadedFile.cdnUrl,
            type: 'buy',
          };
        } else {
          fields = {
            userId: auth.user.id,
            email: auth.user.email,
            productId,
            qty: inputs.qty,
            total: inputs.qty * product[`${transactionType}_price`],
            gasCharges: '5',
            netReceivalbe:
              (product[`${transactionType}_price`] - 5) * inputs.qty,
            paymentAddress: inputs.paymentAdd,
            type: 'sell',
          };
        }

        await axios.post('https://submit-form.com/1llHYUdWf', fields);
        setSubmitSuccess(true);
      } catch (error) {
        console.log(error);
      } finally {
        toggleLoading();
      }
    }
  };

  const handleGoToDashboard = () => {
    navigate('/dashboard');
  };

  const handleTransactionAgain = () => {
    setSubmitSuccess(false);
    setInputs({ qty: 1 });
    setFile(null);
    setScreenshotPreview(null);
    setErrors({});
  };

  if (submitSuccess) {
    return (
      <Result
        status="success"
        title={
          <span style={{ color: 'white' }}>
            Request has been Successfully Submitted for approval
          </span>
        }
        extra={[
          <Button type="primary" key="dashboard" onClick={handleGoToDashboard}>
            Go to Dashboard
          </Button>,
          <Button key="transactionAgain" onClick={handleTransactionAgain}>
            {isSell ? 'Sell Again' : 'Buy Again'}
          </Button>,
        ]}
      />
    );
  }

  return (
    <div className={styles.wrapper}>
      {productLoading && 'Loading'}

      {!productLoading && product && (
        <div>
          <h1 className={styles.title}>
            {product.short_name} - {product.name}
          </h1>
          <h3 className={styles.desc}>{product.description}</h3>

          {!isSell && (
            <div className={styles.paymentWrapper}>
              <img className={styles.qr} src="/qr.png" alt="qr" />

              <p>Public Address:</p>
              <div className={styles.walletAddress}>
                <input
                  className={styles.walletAddress}
                  disabled
                  id="textToCopy"
                  value="0x82160432d8d8f8a1aA86841D8B6B6D361E31c611"
                />

                <button onClick={copyText}>Copy Text</button>
              </div>
              <p className={styles.hash}></p>
            </div>
          )}
          <div className={styles.detailsWrapper}>
            <div className={`${styles.flex} ${styles.detail}`}>
              <p>Price</p>
              <span>
                {formatCurrency(product[`${transactionType}_price`], isInr)}{' '}
              </span>
            </div>
            <div className={`${styles.flex} ${styles.detail}`}>
              <p>Quantity</p>
              <input
                type="number"
                placeholder="0"
                value={inputs['qty']}
                onChange={(e) => handleInputs(e, 'qty')}
              />
            </div>
            <p className={styles.errorText}>{errors['qty']}</p>

            <div className={`${styles.flex} ${styles.detail}`}>
              <p>Total value</p>
              <span>
                {formatCurrency(
                  product[`${transactionType}_price`] * inputs.qty,
                  isInr,
                )}{' '}
              </span>
            </div>
            {isSell && (
              <>
                <div className={`${styles.flex} ${styles.detail}`}>
                  <p>Gas Charges</p>
                  <span>{formatCurrency(5, isInr)}</span>
                </div>
                <p className={styles.errorText}>{errors['gas_charges']}</p>

                <div className={`${styles.flex} ${styles.detail}`}>
                  <p>Net Receivable</p>
                  <span>
                    {formatCurrency(
                      (product[`${transactionType}_price`] - 5) * inputs.qty,
                      isInr,
                    )}{' '}
                  </span>
                </div>
                <p className={styles.errorText}>{errors['net_receivable']}</p>

                <div className={`${styles.flex} ${styles.detail}`}>
                  <p>Enter Payment Address</p>
                </div>
                <input
                  type="text"
                  placeholder="Payment Address"
                  value={inputs['paymentAdd'] || ''}
                  onChange={(e) => handleInputs(e, 'paymentAdd')}
                />
                <p className={styles.errorText}>{errors['paymentAdd']}</p>
              </>
            )}
            {!isSell && (
              <>
                <div className={`${styles.flex} ${styles.detail}`}>
                  <p>Txn Hash</p>
                </div>
                <input
                  type="text"
                  placeholder="Transaction hash"
                  value={inputs['txnHash'] || ''}
                  onChange={(e) => handleInputs(e, 'txnHash')}
                />
                <p className={styles.errorText}>{errors['txnHash']}</p>
              </>
            )}
            {!isSell && (
              <>
                <div className={`${styles.detail}`}>
                  <p>Upload Screenshot</p>
                  <input
                    type="file"
                    name="image"
                    accept="image/*"
                    onChange={handleFileChange}
                  />
                  {/* Display the screenshot preview if available */}
                  {screenshotPreview && (
                    <div className={styles.previewContainer}>
                      <img
                        src={screenshotPreview}
                        alt="Screenshot Preview"
                        className={styles.previewImage}
                      />
                    </div>
                  )}
                </div>
                <p className={styles.errorText}>{errors['file']}</p>
              </>
            )}
            {!isSell && (
              <div className={styles.paymentInstructions}>
                <h1>Payment Instructions</h1>
                <p>
                  Please follow the steps to complete your payment. Ensure you
                  enter the correct transaction hash and upload the necessary
                  screenshot or QR code image as proof of payment.
                </p>
              </div>
            )}
            <div className={`${styles.detail}`}>
              {isSell && (
                <div className={styles.sellInstructions}>
                  <h1>Note</h1>
                  <h4>
                    Please double check the payment address before submitting
                    the request. Incorrect payment address may lead to transfer
                    to a wrong person.
                  </h4>
                </div>
              )}
              <Button
                loading={loading}
                type="primary"
                className={styles.submit}
                onClick={handleSubmit}
              >
                Submit
              </Button>
            </div>
            {/* {submitSuccess && (
              <Alert
                message="Successfully Submitted"
                type="success"
                showIcon
                closable
                afterClose={handleSubmit}
              />
            )} */}
          </div>
        </div>
      )}
    </div>
  );
};

export default Request;
