import { useCallback, useEffect, useMemo, useState } from 'react';
import instance from '../server/axios';

const useApi = (
  url,
  options,
  { onError = () => {}, onSuccess = () => {} } = {},
) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const reqOptions = useMemo(() => {
    return { ...options };
  }, [options]);

  const call = useCallback(() => {
    setLoading(true);
    (async () => {
      try {
        const data = await instance(url, reqOptions);
        setData(data.data);
        onSuccess(data.data);
      } catch (error) {
        setError(error);
        onError(error);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    call();
  }, [url]);

  return {
    data,
    loading,
    error,
    refresh: call,
  };
};

export default useApi;
