import React from 'react';
import { Outlet } from 'react-router-dom';
import Navbar from '../components/Navbar';
import useAuth from '../hooks/useAuth';

const Root = () => {
  const auth = useAuth();
  return (
    <>
      <Outlet />
      {auth.user && <Navbar />}
    </>
  );
};

export default Root;
